#home {
    position: relative;
    background: url("../../assets/bgIMG.png");
    background-size: cover;
    background-position: center;
    background-repeat: repeat;
  
    // disable the default padding styles
    .app__wrapper {
      padding: 0;
  
      .copyright {
        display: none;
      }
    }
  }
  

  
 
  
  .app__header-badge {
    width: 100%;
    flex-direction: column;
  
    .badge-cmp,
    .tag-cmp {
      padding: 1rem 2rem;
      background: var(--white-color);
      border-radius: 15px;
      flex-direction: row;
      width: auto;
  
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    }
  
    .tag-cmp {
      flex-direction: column;
      margin-top: 3rem;
  
      p {
        width: 100%;
        text-transform: uppercase;
        text-align: center;
      }
    }
  
    span {
      font-size: 2.5rem;
  
      @media screen and (min-width: 2000px) {
        font-size: 5rem;
      }
    }
  
    @media screen and (max-width: 1200px) {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  
